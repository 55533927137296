<template>
	<div class="box">
		<languages v-if="!checkUserOrNot"></languages>
		<img class="logo" src="../../../public/loginLogo.png" />
		<h1 class="welcome-back" v-if="!checkUserOrNot">{{$t('欢迎回来')}}</h1>
		<h1 class="welcome-back" v-else>{{$t('输入你的密码')}}</h1>
		<div class="input-box">
			<label v-show="emailActive" :class="{ active: !emailActive }"
				:style="emailMsg ? 'color:#d00e17' : ''">{{ (!emailPlaceholder ? '' : emailPlaceholder === 1 ? $t('邮箱') : $t('手机')) || $t('电话或邮箱') }}</label>
			<input name="username" @focus="emailActive = true" @blur="emailBlur()" @keyup.enter="next()"
				v-model="userEmail"
				:placeholder="emailActive ? '' : (!emailPlaceholder ? '' : emailPlaceholder === 1 ? $t('邮箱') : $t('手机')) || $t('电话或邮箱')"
				:style="emailMsg ? 'border: 1px solid #d00e17;' : ''" />
			<!-- <span class="error" v-if="emailMsg != ''"><i class="iconfont icon-error"></i>{{emailMsg === $t('填写格式有误')?$t('填写格式有误'):$t('用户不存在')}}</span> -->
			<span class="error" v-if="emailRet === 5"><i class="iconfont icon-error"></i>{{ $t('电话号码不能为空') }}</span>
			<span class="error" v-if="emailRet === -1"><i class="iconfont icon-error"></i>{{ $t('填写格式有误') }}</span>
			<span class="error" v-if="emailRet === 2"><i class="iconfont icon-error"></i>{{ $t('用户不存在') }}</span>
			<!-- <span class="error" v-if="emailRet === 1"><i class="iconfont icon-error"></i>{{ $t('验证码已发送请勿重复获取') }}</span> -->
			<!-- <span class="error" v-if="emailRet === -1"><i class="iconfont icon-error"></i>{{ $t('短信发送失败') }}</span> -->
		</div>
		<div class="input-box" v-if="checkUserOrNot">
			<label class="pass-label" v-show="passActive" :class="{active:!passActive}">{{ $t('密码') }}</label>
			<input name="password" :type="showPassword?'text':'password'" @focus="passActive = true" @blur="passBlur()"
				@keyup.enter="next()" v-model="password" :placeholder="passActive?'': this.$t('密码') " />
			<span @click="togglePassword()" v-if="!showPassword" class="glyphicon glyphicon-eye-open"></span>
			<span @click="togglePassword()" v-else class="glyphicon glyphicon-eye-close"></span>
			<span class="error" v-if="passMsg != ''"><i class="iconfont icon-error"></i>{{passMsg}}</span>
			<span class="forget" @click="toReset()">{{ $t('忘记密码') }}</span>
		</div>
		<button class="continue" @click="next()" :disabled="!loginOrNot">{{$t("确定")}}</button>
		<br />
		<!--  手机快捷登录 -->
		<!-- <button class="continue" @click="$router.push('mobileCode')" >{{$t("手机验证登录")}}</button> -->
		<span class="sign-up">{{$t('还没有账号')}} ? <span @click="toSignup()">{{ $t('去注册') }}</span> </span>
		<!-- <span class="sign-mobile" @click="toMobileLogin()"> {{ $t('手机验证登录') }} </span> -->
	</div>
</template>
<script>
	import {
		userLogin,
		checkUser
	} from '../../config/api.js'
	import languages from "../../components/languages/index.vue"

	export default {
		name: 'login',
		components: {
			languages
		},
		data() {
			return {
				emailActive: false,
				passActive: false,
				userEmail: '',
				password: '',
				showPassword: false,
				checkUserOrNot: false,
				emailMsg: '',
				emailRet: null,
				emailPlaceholder: null,
				passMsg: '',
				loginOrNot: true
			}
		},
		mounted() {

		},
		methods: {
			emailBlur() {
				if (this.userEmail == '') {
					this.emailActive = false
				}
			},
			passBlur() {
				if (this.password == '') {
					this.passActive = false
				}
			},
			togglePassword() {
				this.showPassword = !this.showPassword;
			},
			next() {
				if (!this.mobilelogin) { // 手机登录验证

				}

				if (!this.userEmail) {
					this.emailRet = 5;
					this.emailMsg = this.$t('电话号码不能为空');
					return;
				}
				let that = this
				if (!this.checkUserOrNot) {
					checkUser({
						"username": this.userEmail
					}).then(res => {
						/**regMethod为注册格式 -1为不合规 1为邮箱 2为手机  */
						if (res.data["regMethod"] === -1) {
							that.emailRet = res.data["regMethod"];
							that.emailMsg = that.$t('填写格式有误');
							return;
						}
						if (res.data["regMethod"] === 1) {
							that.emailPlaceholder = res.data["regMethod"]
						}
						if (res.data["regMethod"] === 2) {
							that.emailPlaceholder = res.data["regMethod"]
						}
						that.checkUserOrNot = res.data["checkOrNot"]
						if (!that.checkUserOrNot) {
							that.emailMsg = this.$t('用户不存在');
							that.emailRet = 2
							that.emailActive = true
						} else {
							that.emailMsg = ''
							that.emailRet = null
							that.inputPassword = true
						}
					})
				} else {
					this.loginOrNot = false
					userLogin({
						"username": this.userEmail,
						"password": this.password
					}).then(res => {
						if (res.data["status"] == "error") {
							that.passMsg = this.$t('账号或密码错误')
							that.loginOrNot = true
						} else {
							that.passMsg = '';
							let userData = {
								"token": res.data["token"],
								"nickname": res.data["nickname"],
								"username": that.userEmail
							}

							localStorage.setItem("userData", JSON.stringify(userData));
							// location.href = "/ai-voice"
							this.$router.push("userInfo")
							// this.$router.push("ai-voice")
						}
					})
				}
			},
			toSignup() {
				this.$router.push({
					name: 'register'
				})
			},
			toReset() {
				this.$router.push({
					name: 'reset',
					query: {
						'email': this.userEmail
					}
				})
			},
			toMobileLogin() {
				this.$router.push('/mobileLogin');
			},
		}
	}
</script>
<style lang="less" scoped>
	.box {
		width: 100%;
		margin: 0;
		text-align: center;
		padding-top: 8%;

		.logo {
			width: 100px;
			height: 100px;
			display: block;
			margin: 1% auto 4rem auto;
			border-radius: 12%;
			border: 3px solid #10a37f;
		}

		.welcome-back {
			font-size: 20px;
		}

		.input-box {
			width: 25%;
			margin: 3rem auto;
			position: relative;
			text-align: left;

			label {
				display: block;
				color: #10a37f;
				position: absolute;
				margin-left: 20%;
				margin-top: 1rem;
				padding: 0 .5rem;
				position: absolute;
				// top: -50%;
				transform: translate(-50%, -100%);
				transition: all .3s ease-in-out;
				transition-delay: .1s;
				background-color: white;
			}

			label.active {
				top: -2rem;
				left: 2%;
				transform: translate(0, -20%) scale(0.8);
			}

			.pass-label {
				margin-left: 15%;
			}

			input {
				height: 52px;
				width: 100%;
				border: 1px solid;
				border-radius: 3px;
				padding: 0 10px;
				border-color: #10a37f;
				font-size: 14px;

				&:-webkit-autofill,
				textarea:-webkit-autofill,
				select:-webkit-autofill {

					-webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
					background-color: transparent;
					background-image: none;
					transition: background-color 50000s ease-in-out 0s; //背景色透明 生效时长 过渡效果 启用时延迟的时间
				}
			}

			input:focus {
				outline: none;
				border: 1px solid #10a37f;
			}

			input:visited {
				background-color: #000;
			}

			input::-webkit-input-placeholder {
				margin-left: 10%;
				text-indent: 20px;
			}

			.glyphicon-eye-open,
			.glyphicon-eye-close {
				position: absolute;
				right: 0;
				top: 2rem;
				right: 5%;
			}

			.glyphicon-eye-open:hover,
			.glyphicon-eye-close:hover {
				cursor: pointer;
			}

			.error {
				color: #d00e17;
				font-size: 12px;
				margin-top: .5rem;
				display: inline-block;

				.icon-error {
					vertical-align: middle;
					margin-right: .5rem;
				}
			}

			.forget {
				color: #10a37f;
				margin-top: 1rem;
				display: block;
			}

			.forget:hover {
				cursor: pointer;
			}
		}

		.continue {
			background-color: #10a37f;
			color: white;
			border: 0px;
			outline: none;
			border-radius: 3px;
			height: 52px;
			width: 25%;
			margin-bottom: 3rem;
		}

		.continue:nth-last-child(2) {
			background-color: #fff;
			color: #000;
		}

		.continue:nth-last-child(2):hover {
			background-color: #f5f5f5;
		}

		.sign-up {

			display: block;
			font-size: 14px;

			span {
				color: #10a37f;
			}

			span:hover {
				cursor: pointer;
			}
		}

		.sign-mobile:hover {
			cursor: pointer;
		}
	}

	@media screen and (max-width: 768px) {

		.input-box,
		.continue {
			width: 80% !important;
		}

		.box {
			.logo {
				margin-top: 5rem;
			}

			.welcome-back {
				font-size: 2.5rem;
			}
		}
	}
</style>
